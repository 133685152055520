import React from 'react';
import { bool, string } from 'prop-types';
import { loadable } from 'nordic/lazy';
import { RecommendationsList, RecommendationsListPads } from '@recommendations-fe/list';
import RecommendationsIconFactory from './icon-factory';

const RecommendationsCardHorizontal = loadable(() => import('./recomendations-card'), {
  resolveComponent: components => components.RecommendationsCardHorizontal,
});

RecommendationsCardHorizontal.preload();

const Recommendations = ({ isPolycard, thousandSeparator, decimalSeparator, ...props }) => (
  <RecommendationsList
    ListComponent={RecommendationsListPads}
    listType="pads"
    Card={RecommendationsCardHorizontal}
    IconFactory={RecommendationsIconFactory}
    contextProps={{
      type: 'list',
      thousandSeparator,
      decimalSeparator,
    }}
    cardProps={{
      polyClassName: 'recos-polycard poly-card--mobile',
    }}
    isPolycard={isPolycard}
    {...props}
  />
);

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
};

export default Recommendations;
